<template>
  <div class="rightLink">
    <div class="bottomDiv">
      <div class="title">统计分析</div>
      <div class="statistics">
        <div>
          <router-link to="/device_manage/station_manage">
            <p class="s_title" style="color: #ff6060">充电站总数(个)</p>
            <p class="s_water" style="color: #ff6060">{{ total }}</p>
            <!-- <p class="s_profress">+0.1%</p> -->
          </router-link>
        </div>

        <div>
          <router-link to="/device_manage/charg_manage-type=undefined">
            <p class="s_title" style="color: #2ed2aa">设备总数(个)</p>
            <p class="s_water" style="color: #2ed2aa">{{ tableData2.device_count }}</p>
            <!-- <p class="s_profress">+0.1%</p> -->
          </router-link>
        </div>
        <div>
          <router-link to="" @click="toDevice(0)">
            <p class="s_title" style="color: #29a1ec">正常设备(个)</p>
            <p class="s_water" style="color: #29a1ec">{{ tableData2.normal_device }}</p>
            <!-- <p class="s_profress">+0.1%</p> -->
          </router-link>
        </div>
        <div>
          <router-link to="" @click="toDevice(1)">
            <p class="s_title p_bottom" style="color: #6e39e8">使用中设备(个)</p>
            <p class="s_water" style="color:#6e39e8">{{ tableData2.charge }}</p>
            <!-- <p class="s_profress">+0.1%</p> -->
          </router-link>
        </div>
        <div>
          <router-link to="" @click="toDevice(2)">
            <p class="s_title p_bottom" style="color: #94bb22">异常设备(个)</p>
            <p class="s_water" style="color: #94bb22">{{ tableData2.state_device }}</p>
            <!-- <p class="s_profress">+0.1%</p> -->
          </router-link>
        </div>
        <div>
          <router-link to="/device_manage/station_manage">
            <p class="s_title p_bottom" style="color: #ffa300">设备使用率</p>
            <p class="s_water" style="color: #ffa300">{{ tableData2.usage_rate }}%</p>
            <!-- <p class="s_profress">-0.1%</p> -->
          </router-link>
        </div>
      </div>
    </div>
    <div class="bottomDiv" style="margin-top: 20px; height: 550px">
      <div class="title">充电站查询</div>
      <div class="formDiv" style="width:1200px">
        <el-form label-position="right" label-width="90px">
          <el-row :gutter="20" style="margin-bottom: 18px">
            <el-col :span="6">
              <el-form-item label="选择省份:">
                <el-select
                  v-model="FormData.province"
                  placeholder="请选择省"
                  @change="handleProvinceChange"
                  style="width: 100%"
                  clearable
                >
                  <el-option
                    v-for="provinces in provinceList"
                    :key="provinces.value"
                    :value="provinces.label"
                    :label="provinces.label"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="选择市:">
                <el-select
                  v-model="FormData.city"
                  placeholder="请选择市"
                  @change="handleCityChange"
                  clearable
                >
                  <el-option
                    v-for="citys in cityList[0]"
                    :key="citys.value"
                    :value="citys.label"
                    >{{ citys.label }}</el-option
                  >
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="选择区/县:">
                <el-select v-model="FormData.county" placeholder="请选择区/县" clearable>
                  <el-option
                    v-for="countys in countyList[0]"
                    :key="countys.value"
                    :value="countys.label"
                    >{{ countys.label }}</el-option
                  >
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="站点类型:">
                <el-select
                  v-model="FormData.type_id"
                  placeholder="请选择站点类型"
                  clearable
                >
                  <el-option
                    v-for="car in carList"
                    :key="car.value"
                    :value="car.value"
                    :label="car.label"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="站点名称:">
                <el-input
                  v-model="FormData.station_name"
                  placeholder="请输入站点名称"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="充电站编号:">
                <el-input
                  v-model="FormData.station_number"
                  placeholder="请输入充电站编号"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col v-show="isadmin" :span="6">
              <el-form-item label="运营商:">
                <el-select
                  v-model="FormData.username"
                  placeholder="请选择运营商"
                  clearable
                >
                  <el-option
                    v-for="aa in users"
                    :key="aa.username"
                    :value="aa.username"
                    :label="aa.username"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="1">
              <div class="BtnSearch" @click="searchBtn()">查询</div>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 表格部分 -->
      <div class="tableDiv">
        <el-table
          :data="tableData"
          border
          v-loading="loading"
          height="337px"
          style="
            width: 90%;
            margin: 20px auto;

            overflow-y: auto;
          "
        >
          <el-table-column label="序号" type="index" align="center" width="60">
            <template #default="{ $index }">
              {{ (currentPage - 1) * pageSize + $index + 1 }}
            </template>
          </el-table-column>
          <el-table-column
            label="充电站编号"
            prop="station_number"
            width="95"
          />
          <el-table-column label="运营商" prop="username" width="90" />
          <el-table-column label="站点名称" prop="station_name" width="160" />
          <!-- <el-table-column label="站点类型" prop="type_id" width="180" /> -->
          <el-table-column label="站点类型" prop="type_id" width="90">
            <template #default="scope">
              <span v-if="scope.row.type_id == 2">电动车</span>
              <span v-if="scope.row.type_id == 4">电动汽车</span>
            </template>
          </el-table-column>
          <!-- <el-table-column label="站点地址" prop="station_addr" width="200" /> -->
          <el-table-column label="省" prop="province" width="80" />
          <el-table-column label="市" prop="city" width="80" />
          <el-table-column label="县/区" prop="county" width="80" />
          <el-table-column label="社区" prop="community" width="190" />
          <el-table-column label="详细地址" prop="station_addr" width="100" />
          <el-table-column label="经度" prop="cord_j" width="100" />
          <el-table-column label="纬度" prop="cord_w" width="100" />
          <!-- <el-table-column label="枪头数" prop="plug" width="70" /> -->
          <!-- <el-table-column label="自动退费" prop="is_return"  width="95">
            <template #default="scope">
              <span v-if="scope.row.is_return == 0">关闭</span>
              <span v-if="scope.row.is_return == 1">开启</span>
            </template>
          </el-table-column> -->

          <el-table-column
            label="操作"
            width="220"
            align="center"
            fixed="right"
          >
            <template #default="scope">
              <!-- <div class="BtnSearch1 BtnSearch" 
                @click="handleAdd(scope.$index, scope.row)"
                >
                  设备添加
                </div> -->
              <!-- <div v-show="scope.row.is_return == 0" class="BtnSearch1 BtnSearch" @click="handleOp(scope.$index, scope.row)">开启退费</div> -->
              <!-- <el-button v-show="scope.row.is_return == 0" round color='#42a7a9' plain size="mini" @click="handleOp(scope.$index, scope.row)">开启退费</el-button> -->
              <!-- <div v-show="scope.row.is_return == 1" class="BtnSearch1 btnclose" @click="handleCl(scope.$index, scope.row)">关闭退费</div> -->
              <!-- <el-button style="margin:0  !important" v-show="scope.row.is_return == 1" round type="warning" plain size="mini" @click="handleCl(scope.$index, scope.row)">关闭退费</el-button> -->

              <!-- <div class="BtnSearch1 BtnSearch" @click="showcharg(scope.$index, scope.row)">查看设备</div> -->
              <el-button
                round
                color="#42a7a9"
                plain
                size="mini"
                @click="showcharg(scope.$index, scope.row)"
                >查看设备</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination
            :current-page="FormData.page"
            :page-size="pageSize"
            :small="small"
            layout="total, slot, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
            <template #default>
              <span class="el-pagination__total">{{ Math.ceil(total / pageSize) }} 页 </span>
            </template>
          </el-pagination>
        </div>
      </div>
      <div v-dialogdrag>
        <el-dialog
          v-model="Dialog.editLevy"
          width="80%"
          :before-close="closeDialog"
          class="dialog"
        >
          <div class="title">设备信息</div>
          <div class="tableDiv">
            <el-table
              ref="multipleTableRef"
              :data="tableData1"
              border
              v-loading="loading1"
              height="480px"
              style="width: 90%; margin: 20px auto; overflow-y: auto"
            >
              <!-- <el-table-column label="设备编号" prop="device_id" /> -->
              <!-- <el-table-column label="设备名称" prop="station_name" /> -->
              <el-table-column label="设备编号" prop="device_id" width="130" />
              <el-table-column label="省" prop="province" width="80" />
              <el-table-column label="市" prop="city" width="80" />
              <el-table-column label="县" prop="county" width="80" />
              <el-table-column label="详细地址" prop="community" width="150" />
              <!-- <el-table-column label="社区" prop="community" /> -->
              <!-- <el-table-column label="充电类型" prop="contact_way" />
          <el-table-column label="枪头总数" prop="wx_id" />
          <el-table-column label="功率" prop="mailbox" /> -->
              <!-- <el-table-column label="充电枪id" prop="mailbox" /> -->
              <el-table-column label="生产厂商" prop="manufacturer" />
              <el-table-column label="规格类型" prop="Specification_type" />
              <el-table-column label="计费模板" prop="price_id" />
              <el-table-column label="功率" prop="wx_id" />
              <el-table-column label="开通时间" prop="opening_time" />
              <el-table-column label="开始时间" prop="create_time" />
              <el-table-column label="结束时间" prop="update_time" />
              <el-table-column label="工作状态" prop="state" />
            </el-table>
            <div class="pagination">
              <el-pagination
                :current-page="currentPage"
                :page-size="pageSize"
                :small="small"
                layout="total"
                :total="total1"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange1"
              />
            </div>
          </div>
          <!-- <template #footer>
            <span class="dialog-footer">
              <el-button class="Btn" @click="closeDialog">取消</el-button>
              <el-button class="Btn" @click="submitDialog">提交</el-button>
            </span>
          </template> -->
        </el-dialog>
      </div>
    </div>

    <div class="bottomDiv" style="height: 18vh; margin-top: 20px">
      <div class="title">设备管理快捷方式</div>
      <div class="statistics1">
        <div>
          <router-link to="/device_manage/station_add">
            <img src="../../assets/img/icon_1.png" alt="" />
            <p>充电站添加</p>
          </router-link>
        </div>
        <div>
          <router-link to="/device_manage/station_manage">
            <img src="../../assets/img/icon_2.png" alt="" />
            <p>充电站编辑</p>
          </router-link>
        </div>
        <div>
          <router-link to="/device_manage/device_add">
            <img src="../../assets/img/icon_4.png" alt="" />
            <p>设备添加</p>
          </router-link>
        </div>
        <div>
          <router-link to="/device_manage/charg_manage">
            <img src="../../assets/img/icon_3.png" alt="" />
            <p>设备管理</p>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { onMounted } from "vue";
import { ref } from "vue";
import { onMounted, reactive, toRefs } from "vue-demi";
import {
  sel_station,
  return_switch,
  sel_station_all_info,
  operator_data,
} from "@/request/api";
import { ElMessage } from "element-plus";
import { regionData } from "element-china-area-data";
import { useRouter } from "vue-router";
export default {
  name: "station_search",
  setup() {
    const router = useRouter();
    const data = reactive({
      //表单数据
      carList: [
        {
          value: 2,
          label: "电动车",
        },
        {
          value: 4,
          label: "电动汽车",
        },
      ],
      car: "",
      FormData: {
        operator_id: sessionStorage.getItem("userID"),
        page:1
      },
      tableData: [],
      tableData1: [],
      tableData2: {},
      device_list: ["22", "33", "44", "55"],
      //   弹框

      loading: false,
      loading1: false,
      station_id: "",
      options: regionData,
      selectedOptions: "",
      //   分页
      currentPage: 1,
      pageSize: 20,
      total: 0,
      total1:0,
      checked: 0,
      small: true,

      // 省市县
      regionData,
      provinceList: [],
      cityList: [],
      countyList: [],
      province: "",
      city: "",
      county: "",
      Dialog: {
        editLevy: false,
        DialogData: {},
      },
      isadmin: false,
      users: [],
    });
    const searchBtn = () => {
      data.FormData.page = 1;
      getList();
    };
const toDevice = (state) => {
      sessionStorage.setItem("state",state)
      router.push({
        path:"/device_manage/charg_manage-type=undefined"
      })
    }
    const getList = () => {
      const dataa = data.FormData;

      data.loading = true;
      sel_station(dataa).then((res) => {
        if (res) {
          console.log(res, "查询充电站");
          data.loading = false;
          if (res.code == 200) {
            data.tableData = res.data;
            data.tableData2 = res;
            data.tableData2.usage_rate = (res.usage_rate * 100).toFixed(2);

            data.total = res.station_sum;
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
    };
    const handleOp = (index, row) => {
      const dataa = {
        id: row.id,
        is_return: 1,
      };
      return_switch(dataa).then((res) => {
        if (res) {
          ElMessage.success("开启成功");
          getList();
        } else {
          ElMessage.error("开启失败");
        }
      });
    };
    const handleCl = (index, row) => {
      const dataa = {
        id: row.id,
        is_return: 0,
      };
      return_switch(dataa).then((res) => {
        if (res) {
          ElMessage.success("关闭成功");
          getList();
        } else {
          ElMessage.error("关闭失败");
        }
      });
    };
    const showcharg = (index, row) => {
      data.Dialog.editLevy = true;
      data.station_id = row.id;
      // data.Dialog.DialogData = row;
      const dataa = {
        station_id: row.id,
      };
      data.loading1 = true;
      sel_station_all_info(dataa).then((res) => {
        if (res) {
          data.loading1 = false;
          if (res.code == 200) {
            // data.tableData = res.station_dict;
            data.tableData1 = res.data;
            data.total1 = data.tableData1.length;
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
    };
    // const todevices = () => {
    //   router.push({
    //     path: "/device_manage/charg_manage",
    //     query: {
    //       station_id: data.station_id,
    //     },
    //   });
    // };
    const getUsername = () => {
      const username1 = localStorage.getItem("username");
      const users1 = [];
      operator_data().then((res) => {
        console.log(res, "sssssss");
        data.users = res;
        res.forEach((a) => {
          users1.push(a.username);
        });
        if (users1.indexOf(username1) == -1) {
          data.isadmin = true;
        } else {
          data.isadmin = false;
          data.FormData.username = username1;
        }
      });
    };

    const initProvinceList = () => {
      // 初始化省份列表数据源
      regionData.forEach((tab) => {
        data.provinceList.push(tab);
      });
    };
    const handleProvinceChange = (val) => {
      data.cityList = [];
      data.countyList = [];
      data.province = val;
      data.FormData.city = "";
      data.FormData.county = "";
      initCityList();
    };

    const initCityList = () => {
      // 初始化城市列表数据源
      data.provinceList.forEach((tab1) => {
        if (tab1.label == data.province) {
          data.cityList.push(tab1.children);
        }
      });
    };

    const handleCityChange = (value1) => {
      data.countyList = [];
      data.city = value1;
      data.FormData.county = "";
      initCountyList();
    };

    const initCountyList = () => {
      // 初始化县/区列表数据源
      // 初始化城市列表数据源
      data.cityList[0].forEach((tab2) => {
        // tab2.forEach((tab3)=>{
        //    if(tab3.label==this.selectedCity){
        //  this.countyList.push(tab3.children)
        // }
        // })
        if (tab2.label == data.city) {
          data.countyList.push(tab2.children);
        }
      });
    };
    const handleCurrentChange = (val) => {
      data.FormData.page = val;
      getList();
    };
    const handleCurrentChange1 = (val) => {
      data.currentPage = val;
      data.loading1 = true;
      sel_station_all_info({
        station_id: data.station_id,
      }).then((res) => {
        data.loading1 = false;
        if (res) {
          if (res.code == 200) {
            // data.tableData = res.station_dict;
            data.tableData1 = res.data;
            data.total1 = data.tableData1.length;
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
    };
    const closeDialog = ()=>{
      data.Dialog.editLevy = false;
      data.tableData1 = [];
      data.total1 = 0;
    }
    onMounted(() => {
      getUsername();
      getList();
      initProvinceList();
    });
    return {
      ...toRefs(data),
      ref,
      handleOp,
      handleCl,
      showcharg,
      searchBtn,
      getList,
      getUsername,
      initProvinceList,
      handleProvinceChange,
      handleCityChange,
      initCityList,
      initCountyList,
      // todevices,
      handleCurrentChange,
      handleCurrentChange1,
      toDevice,
      closeDialog
    };
  },
};
</script>
<style scoped>
.formDiv {
  margin-top: 10px;
  height: 100px;
}
.el-row {
  height: 45px;
}
.el-select /deep/ .el-input__wrapper {
  width: 288px !important;
  --el-input-focus-border-color: #7bd8d3;
}
.btn {
  color: #fff;
  background-color: #03beb0;
  border-color: #03beb0;
  width: 55px;
  height: 28px;
  line-height: 28px;
  margin-left: -100px;
}
.Btns {
  color: #fff;
  background-color: #03beb0;
  border-color: #03beb0;
  width: 175px;
  margin-left: 40px;
  margin-top: 20px;
}
.btn1 {
  margin-left: 340px;
}
.btn:hover {
  background-color: #05d5c7;
  border-color: #05d5c7;
}

.btn:focus {
  background-color: #03beb0;
  border-color: #03beb0;
}

.BtnSearch1 {
  float: left;
  width: 100px;
  margin: 0 4px;
}
.btnclose {
  background-color: #036363;
  color: white;
  /* width: 55px; */
  line-height: 30px;
  text-align: center;
  border-radius: 20px;
  font-size: 14px;
}
.pagination {
  display: flex;
  justify-content: space-between;
  padding: 0 67px;
  margin-top: -10px;
}

.bottomDiv {
  height: auto;
}
.statistics {
  display: flex;
  width: 90%;
  padding-top: 15px;
  /* background: red; */
  margin: 0 auto;
  justify-content: space-between;
  flex-wrap: wrap;
}
.statistics1 {
  display: flex;
  width: 90%;
  margin: 0 auto;
  justify-content: space-between;
  flex-wrap: wrap;
}
.statistics1 > div > a {
  text-decoration: none;
  color: black;
}

.statistics1 > div > a > p{
  text-align: left;
}
.s_title {
  font-size: 16px;
  text-align: center;
}

.p_bottom .el-icon {
  text-align: center;
}
.s_water {
  line-height: 40px;
  font-size: 19px;
  text-align: center;
  /* font-weight: bold; */
}
.s_profress {
  line-height: 25px;
}

.statistics > div {
  width: 12%;
  padding: 10px 20px;
  text-align: center;
  background-size: 100% 100%;
  text-align: center;
  line-height: 35px;
  margin-bottom: 8px;
}
.statistics > div:nth-child(1) {
  background-image: url("../../assets/img/six.png");
  color: #ff6060 !important;
}
.statistics > div:nth-child(2) {
  background-image: url("../../assets/img/four.png");
  color: #2ed2aa !important;
}
.statistics > div:nth-child(3) {
  background-image: url("../../assets/img/five.png");
  color: #29a1ec !important;
}
.statistics > div:nth-child(4) {
  background-image: url("../../assets/img/three.png");
  color: #6e39e8 !important;
}
.statistics > div:nth-child(5) {
  background-image: url("../../assets/img/two.png");
  color: #94bb22 !important;
}
.statistics > div:nth-child(6) {
  background-image: url("../../assets/img/one.png");
  color: #ffa300 !important;
}
.statistics > div > .el-progress {
  width: 45%;
}
.el-progress /deep/ .el-progress-bar__outer {
  --el-border-color-lighter: #fff;
}
.statistics1 > div {
  width: 13%;
  margin: 10px auto;
  /* border: 1px solid red; */
  padding: 10px;
  /* height: 200px; */
}

.statistics1 > div p {
  font-size: 16px;
  text-align: center;
  line-height: 30px;
}
</style>
